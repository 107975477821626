<template>
    <div class="videoPlayer">
        <h1>测试页面</h1>
        
        <h2>{{$route.params}}</h2>








    </div>
</template>


<script>
import {playback} from '@/api/selectClass.js'

export default {
    components: {},
    data() {
        return {
            activeName: '1',
            id: '',
            name: ''
        };
    },
    created() {
        // let data =new FormData()
        // data.append('channelId','3056767')
        // playback(data).then(res =>{
        //     console.log('获取回放----------',res)
        // })
        
    },
    methods: {
        

    }
};
</script>

<style lang="less" scoped>
.videoPlayer {
    height: 100vh;
    background-color: #f5f5f5;
}
</style>


